import axios from 'axios';
import MessageRejectedBody from 'components/common/ui/AI/MessageRejectedBody';
import { GLOBAL } from 'constants/index';
import React, {
    Dispatch,
    PropsWithChildren,
    ReactNode,
    RefObject,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState
} from 'react';
import { useLocation } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import { FetchStatus } from 'types';
import { FileUpload, ChatMessage } from 'types';
import { NetworkService } from '../utils';
import { AppContext } from './AppContext';

interface AiContextInterface {
    aiStatus: FetchStatus;
    setAiStatus: Dispatch<SetStateAction<FetchStatus>>;
    chatHistory: ChatMessage[];
    setChatHistory: Dispatch<SetStateAction<ChatMessage[]>>;
    startNewChat: () => Promise<void>;
    interactionEnabled: boolean;
    setInteractionEnabled: Dispatch<SetStateAction<boolean>>;
    aiChatWindowRef: RefObject<HTMLDivElement>;
    busy: boolean;
    handleSendMessage: (message: Record<string, any>) => void;
    conversationStates: ConversationState[];
    setConversationStates: Dispatch<SetStateAction<ConversationState[]>>;
    connectToTheHighestPriorityConversation: () => void;
    currentlyUsedChatId: string | null;
    setCurrentlyUsedChatId: Dispatch<SetStateAction<string | null>>;
    findNewHighestPriorityConversation: () => void;
    uploadedFiles: Record<string, FileUpload>;
    setUploadedFiles: Dispatch<SetStateAction<Record<string, FileUpload>>>;
    disclaimerActive: boolean;
    setDisclaimerActive: Dispatch<SetStateAction<boolean>>;
}

interface ConversationState {
    conversationId: string;
    taxEntityId: string;
    unreadMessages: number;
}

export const AiContext = React.createContext({} as AiContextInterface);

export const AiProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [aiStatus, setAiStatus] = useState<FetchStatus>('idle');
    const [interactionEnabled, setInteractionEnabled] = useState(false);
    const [busy, setBusy] = useState(false);
    const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
    const [conversationStates, setConversationStates] = useState<ConversationState[]>([]);
    const jwtToken = NetworkService.getToken();
    const [highestPriorityChatId, setHighestPriorityChatId] = useState<string | null>(null);
    const [currentlyUsedChatId, setCurrentlyUsedChatId] = useState<string | null>(null);
    const [uploadedFiles, setUploadedFiles] = useState<Record<string, FileUpload>>({});
    const [disclaimerActive, setDisclaimerActive] = useState(false);

    const aiChatWindowRef = useRef<HTMLDivElement>(null);

    const { aiChatWindowOpen } = useContext(AppContext);

    const location = useLocation();

    useEffect(() => {
        console.log('location', location.pathname);
    }, [location]);

    const handleSendMessage = useCallback((message: Record<string, any>) => sendChatMessage(JSON.stringify(message)), []);

    const { sendMessage: sendConversationStateMessage } = useWebSocket(
        `${GLOBAL.BASE_URL.replace('http', 'ws')}/api/ai/conversationState`,
        {
            shouldReconnect: () => true,
            onOpen: () => {
                console.log('Converstaion State WebSocket connected');
                sendConversationStateMessage(jwtToken!);
            },
            onMessage: lastMessage => {
                if (lastMessage !== null) {
                    const states = JSON.parse(lastMessage.data);
                    setConversationStates(states);
                }
            },
            onClose: () => console.log('ConversationState WebSocket disconnected')
        }
    );

    function findNewHighestPriorityConversation() {
        const newHighestPriority =
            conversationStates.length > 0
                ? conversationStates.reduce((max, item) => {
                      return max.unreadMessages > item.unreadMessages ? max : item;
                  })
                : null;

        if (highestPriorityChatId === null || (newHighestPriority && newHighestPriority.unreadMessages > 0)) {
            setHighestPriorityChatId(newHighestPriority?.conversationId || null);
        }
    }

    useEffect(() => {
        findNewHighestPriorityConversation();
    }, [conversationStates]);

    const connectToTheHighestPriorityConversation = () => {
        if (highestPriorityChatId) {
            handleSendMessage({ type: 'connect', conversationId: highestPriorityChatId });
            setCurrentlyUsedChatId(highestPriorityChatId);
        } else {
            startNewChat();
        }
        setAiStatus('success');
    };

    const isKeepAliveMessage = (message: MessageEvent<any>) => {
        return ['ping', 'pong'].includes(message.data);
    };

    const updateMessageById = (arr: ChatMessage[], id: string, newMessage: string | ReactNode) => {
        return arr.map(obj => (obj.id === id ? { ...obj, body: newMessage } : obj));
    };

    const { sendMessage: sendChatMessage } = useWebSocket(`${GLOBAL.BASE_URL.replace('http', 'ws')}/api/ai/chat`, {
        shouldReconnect: () => true,
        onOpen: () => {
            if (currentlyUsedChatId !== null) {
                connectToTheHighestPriorityConversation();
            }
            console.log('Chat WebSocket connected');
        },
        onMessage: lastMessage => {
            if (isKeepAliveMessage(lastMessage)) return;

            if (lastMessage !== null) {
                const message: ChatMessage = JSON.parse(lastMessage.data);
                const isMessageIsInChatHistory = chatHistory.find(m => m.id === message.id && m.body !== undefined);
                const isMessageRejected = message.type === 'moderationRejection';

                if (message.type === 'connected') {
                    setChatHistory([]);
                } else if (isMessageIsInChatHistory) {
                    setChatHistory(prev => updateMessageById(prev, message.id!, message.body as string));
                } else if (isMessageRejected) {
                    setChatHistory(prev => [
                        {
                            ...message,
                            body: <MessageRejectedBody />
                        },
                        ...prev
                    ]);
                } else {
                    aiChatWindowRef.current?.scrollTo({ top: aiChatWindowRef.current.scrollHeight });
                    message.interactionEnabled !== undefined && setInteractionEnabled(message.interactionEnabled);
                    message.busy !== undefined && setBusy(message.busy);
                    (message.body !== undefined || message.type === 'error') && setChatHistory(prev => [message, ...prev]);
                }
            }
        },
        onClose: () => {
            console.log('Chat WebSocket disconnected');
        },
        heartbeat: {
            message: 'ping',
            returnMessage: 'pong',
            timeout: 60000, // 1 minute, if no response is received, the connection will be closed
            interval: 10000 // every 10 seconds, a ping message will be sent
        }
    });

    useEffect(() => {
        if (aiChatWindowOpen) {
            setTimeout(() => {
                aiChatWindowRef.current?.scrollTo({ top: aiChatWindowRef.current.scrollHeight });
            }, 50);
        }
    }, [aiChatWindowOpen]);

    const startNewChat = useCallback(async () => {
        try {
            setAiStatus('loading');
            const res = await axios.post(`${GLOBAL.BASE_URL}/api/ai/protected/chats/`);
            const chatId = res.data.toString();
            handleSendMessage({ type: 'connect', conversationId: chatId });
            setCurrentlyUsedChatId(chatId);
            setAiStatus('success');
        } catch (e) {
            setAiStatus('error');
            console.error('e', e);
        }
    }, []);

    return (
        <AiContext.Provider
            value={{
                aiStatus,
                setAiStatus,
                chatHistory,
                setChatHistory,
                startNewChat,
                interactionEnabled,
                setInteractionEnabled,
                aiChatWindowRef,
                busy,
                handleSendMessage,
                conversationStates,
                setConversationStates,
                connectToTheHighestPriorityConversation,
                currentlyUsedChatId,
                setCurrentlyUsedChatId,
                findNewHighestPriorityConversation,
                uploadedFiles,
                setUploadedFiles,
                disclaimerActive,
                setDisclaimerActive
            }}
        >
            {children}
        </AiContext.Provider>
    );
};
